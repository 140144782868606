<template lang='pug'>
v-row.loginWrap.justify-center
	v-overlay(:value='show', opacity='0.8', z-index='6')
		v-progress-circular(indeterminate, color='primary')
	v-card.loginIfo.elevation-0.pa-3
		v-card-text.justify-center
			v-row.flex-column
				v-col.d-flex.loginIfo-div.align-center.justify-center.pt-10
					h2.CARS CARS
				v-col.d-flex.align-center.justify-center.pa-0(cols='12')
					v-form(ref='form')
						span {{ $t("login.account") }}
						v-text-field.Rectangle.mb-7.pb-5(
							ref='account',
							append-icon='person',
							name='login',
							type='text',
							v-model='model.username',
							:rules='nameRules',
							@keyup.enter.native='changeInput',
							required,
							background-color='inputBg',
							color='inputFont',
							outlined
						)
						span {{ $t("login.password") }}
						v-text-field#password.Rectangle.mb-7(
							ref='password',
							append-icon='lock',
							name='password',
							type='password',
							v-model='model.password',
							:rules='passwordRules',
							@keyup.enter.native='handleLogin',
							required,
							background-color='inputBg',
							color='inputFont',
							outlined
						)
						v-checkbox.login-checkBox(v-model='keepAccount', :label='$t("login.rememberAccount")')
				v-col.d-flex.align-center.justify-center.pa-0(cols='12')
					v-btn.defult-btn-color(dark, @click='handleLogin', large, style='width: 100%; height: 60px', rounded) {{ $t("login.login") }}
				v-col.d-flex.align-center.justify-center(cols='12')
					span.version-font {{ $t("login.version") }}: {{ version }}
	.versionWrap
	.versionWrap2
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { getApiServerVersion } from '@/api/Server';
import { operatorLogin } from '@/api/Operator';
import router from '@/router';
import statusCode from '@/assets/constant/statusCode';
import { errorLogger } from '@/util/logger';
import { version } from '../../../package.json';

export default {
	name: 'login',
	data() {
		return {
			windowsWidth: '',
			windowsHeight: '',
			showVersion: true,
			keepAccount: false,
			show: false,
			version: '',
			model: {
				username: '',
				password: '',
			},
			nameRules: [(v) => !!v || this.$t('error.accountIsRequired')],
			passwordRules: [(v) => !!v || this.$t('error.passwordIsRequired')],
			deviceBioEnable: false,
			errorPic: require('@/assets/image/error_icon.png'),
		};
	},
	computed: {
		...mapGetters(['addRouters', 'getCurrency', 'getOperator', 'getDialogShow', 'getStatusDialog', 'getOperatorDefaultRouterTab']),
	},
	methods: {
		changeInput() {
			this.$refs.password.focus();
		},
		async handleLogin() {
			this.show = true;
			// 表格驗證
			if (this.$refs.form.validate()) {
				await this.login();
			}
			this.show = false;
		},
		async login() {
			const loginObj = {
				operatorID: this.model.username,
				operatorPW: this.model.password,
			};
			try {
				const res = await operatorLogin(loginObj);
				if (res.status === statusCode.STATUS_OK) {
					localStorage.setItem('tab', true); // 判斷是否開分頁
					const data = res.result;
					const user = data.user;
					this.setOperator(user);
					window.sessionStorage.setItem('operator', JSON.stringify(user));
					this.setToken(user.token);
					window.sessionStorage.setItem('token', user.token);
					this.setOperatorID(user.operatorID);
					this.setOperatorAC(user.operatorName);
					this.setGroupID(user.groupID);
					// 取得屬於登入者的Menu清單
					window.sessionStorage.setItem('router', JSON.stringify(data.item));
					this.generateRoutes(JSON.parse(window.sessionStorage.getItem('router')));
					// gamelist;
					window.sessionStorage.setItem('gameList', JSON.stringify(data.gamelist));
					this.setGameList(data.gamelist);
					// 店家
					window.sessionStorage.setItem('domain', JSON.stringify(data.domain));
					this.setDomain(data.domain);
					// 店家詳細清單
					window.sessionStorage.setItem('cms', JSON.stringify(data.cms));
					this.setCms(data.cms);
					// 貨幣清單
					window.sessionStorage.setItem('currencyList', JSON.stringify(data.currency));
					this.setCurrencyList(data.currency);
					// 匯率表
					await this.setExchangeRate(data.exchangerate);
					if (!this.getCurrency) {
						localStorage.setItem('currency', 'THB');
					}
					if (this.keepAccount) {
						localStorage.setItem('name', this.model.username);
						localStorage.setItem('keepAccount', this.keepAccount);
					} else if (!this.keepAccount && localStorage.getItem('name')) {
						localStorage.removeItem('name');
						localStorage.removeItem('keepAccount');
					}
					for (let i of this.addRouters) {
						router.addRoute(i);
					}
					let historyIndexPin = data.user.indexPin;
					if (historyIndexPin === 'Dashboard' || historyIndexPin === '' || historyIndexPin === null) {
						this.$router.push('/dashboard');
					} else {
						let operatorDefaultRouter = historyIndexPin.split(',');
						this.$router.push({ name: operatorDefaultRouter[0] });
						this.setOperatorDefaultRouter(historyIndexPin);
						this.setOperatorDefaultRouterTab(operatorDefaultRouter[1]);
					}
				} else if (res.status === statusCode.STATUS_USER_NOT_EXISTED) {
					let userNotExisted = this.$t('error.userNotExisted');
					this.errorDialogStatus(userNotExisted);
				} else if (res.status === statusCode.STATUS_USER_LOCKED) {
					let userLocked = this.$t('error.userLocked');
					this.errorDialogStatus(userLocked);
				} else if (res.status === statusCode.STATUS_INCORRECT_PASSWORD) {
					let userPasswordWrong = this.$t('error.userPasswordWrong');
					this.errorDialogStatus(userPasswordWrong);
				}
			} catch (e) {
				this.errorDialogStatus('Bad Request');
				errorLogger('error', e.message);
			}
		},
		biometric() {
			this.deviceBio();
			if (this.deviceBioEnable) {
				window.getInfoResult = (status) => {
					if (status !== '') {
						window.location = 'native://app/v2/verification?key=loginInfo&callback=verificationResult';
					}
				};
				window.verificationResult = (token) => {
					if (token) {
						const loginInfo = JSON.parse(token);
						this.model.username = loginInfo.operatorAC;
						this.model.password = loginInfo.operatorPW;
						setTimeout(() => {
							this.handleLogin();
						}, 500);
					}
				};
				window.getInfoResult = (status) => {
					if (status !== '') {
						window.location = 'native://app/v2/verification?key=loginInfo&callback=verificationResult';
					}
				};
				window.location = 'native://app/v2/storage?action=GET&key=loginInfo&callback=getInfoResult';
			}
		},
		deviceBio() {
			let userAgent = navigator.userAgent;
			this.deviceBioEnable = userAgent.includes('TokenLogin');
		},
		errorDialogStatus(notifyMsg) {
			let errorTitle = this.$t('error.errorTitle');
			const errorMsg = {
				statusMessage: errorTitle,
				statusSrc: this.errorPic,
				statusText: notifyMsg,
			};
			let stringErrorMsg = JSON.stringify(errorMsg);
			this.setDialogShow(true);
			this.setStatusDialog(stringErrorMsg);
			this.$forceUpdate();
		},
		...mapActions([
			'generateRoutes',
			'setOperatorDefaultRouter',
			'setOperatorDefaultRouterTab',
			'setToken',
			'setOperator',
			'setOperatorID',
			'setOperatorAC',
			'setGroupID',
			'setDomain',
			'setCms',
			'setVersion',
			'setCurrency',
			'setCurrencyList',
			'setExchangeRate',
			'setGameList',
			'setDialogShow',
			'setStatusDialog',
		]),
	},
	async mounted() {
		if (localStorage.getItem('name')) {
			this.model.username = localStorage.getItem('name');
			this.keepAccount = localStorage.getItem('keepAccount');
		}
		try {
			const res = await getApiServerVersion();
			if (res.status === statusCode.STATUS_OK) {
				// this.version = res.result.version;
				this.version = version;
				this.setVersion(this.version);
			}
		} catch (e) {
			// logger('error', e.message);
		}
		// setTimeout(() => {
		// 	this.biometric();
		// }, 500);
	},
	watch: {},
	created() {
		localStorage.removeItem('logout');
	},
	async beforeRouteEnter(to, from, next) {
		if (localStorage.getItem('tab')) {
			sessionStorage.setItem('token', localStorage.getItem('token'));
			sessionStorage.setItem('router', localStorage.getItem('router'));
			sessionStorage.setItem('operator', localStorage.getItem('operator'));
			sessionStorage.setItem('domain', localStorage.getItem('domain'));
			sessionStorage.setItem('gameList', localStorage.getItem('gameList'));
			sessionStorage.setItem('currencyList', localStorage.getItem('currencyList'));
			next('/dashboard');
		} else {
			next();
		}
	},
};
</script>
<style lang="stylus" scoped>
body {
  overflow: hidden;
}
</style>
